var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "center" },
    [
      _c("head-layout", {
        attrs: { "head-title": _vm.$t(`cip.desk.workflow.title.taskTodo`) },
      }),
      _c("grid-head-layout", {
        ref: "gridHeadLayout",
        attrs: {
          "head-title": _vm.$t(`cip.desk.workflow.title.taskTodo`),
          "search-columns": _vm.searchColumns,
        },
        on: {
          "grid-head-search": _vm.gridHeadSearch,
          "grid-head-empty": _vm.gridHeadEmpty,
        },
        model: {
          value: _vm.searchForm,
          callback: function ($$v) {
            _vm.searchForm = $$v
          },
          expression: "searchForm",
        },
      }),
      _c("grid-layout", {
        ref: "gridLayOut",
        attrs: {
          "head-title": _vm.$t(`cip.desk.workflow.title.indexHeadTodo`),
          "table-options": _vm.option,
          "table-data": _vm.data,
          "table-loading": _vm.loading,
          "data-total": _vm.page.total,
          "grid-row-btn": _vm.gridRowBtn,
          page: _vm.page,
        },
        on: {
          "grid-row-detail-click": _vm.rowEdit,
          "page-current-change": _vm.onLoad,
          "page-size-change": _vm.onLoad,
          "page-refresh-change": _vm.onLoad,
          "row-detail": _vm.rowEdit,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.bpmnVisible,
            "append-to-body": "",
            "destroy-on-close": "",
            title: _vm.$t(`cip.desk.workflow.title.indexHeadVisible`),
            width: "70%",
            "custom-class": "wf-dialog",
          },
          on: {
            "update:visible": function ($event) {
              _vm.bpmnVisible = $event
            },
          },
        },
        [
          _c("wf-design", {
            ref: "bpmn",
            staticStyle: { height: "60vh" },
            attrs: { options: _vm.bpmnOption },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }